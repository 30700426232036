interface Webmention {
  id: string;
  source: string;
  target: string;
  createdAt: string;
  title?: string;
}

const webmentionsEndpoint =
  'https://mcxlhfr5sd.execute-api.ap-southeast-2.amazonaws.com/prod/web-mentions';

export const checkWebmentions = async () => {
  const url = document.URL.replace('http://localhost:8080', 'https://pfy.ch');

  const response = await fetch(`${webmentionsEndpoint}/query`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'default',
    body: JSON.stringify({ target: url }),
  });

  const webmentions: Webmention[] = await response.json();

  const webmentionsWrapper =
    document.getElementsByClassName('webmentions')?.[0];

  if (webmentionsWrapper) {
    const noticeElement = document.createElement('p');
    const mentionsList = document.createElement('ul');

    if (webmentions.length > 0) {
      noticeElement.innerHTML =
        'This page has been <a href="https://www.w3.org/TR/webmention/" class="externalLink" target="_blank">Webmentioned</a>:';

      webmentions.map((mention) => {
        const mentionElement = document.createElement('li');
        mentionElement.innerHTML = `${
          new Date(parseInt(mention.createdAt, 10)).toISOString().split('T')[0]
        } @ <a href="${mention.source}"  class="externalLink" target="_blank">${
          mention.title || new URL(mention.source).hostname
        }</a>`;
        mentionsList.append(mentionElement);
      });
    } else {
      noticeElement.innerHTML =
        'This page has not been <a href="https://www.w3.org/TR/webmention/" class="externalLink" target="_blank">Webmentioned</a>.';
    }

    webmentionsWrapper.append(noticeElement);
    webmentionsWrapper.append(mentionsList);
  }
};
