export const svgDiagrams = () => {
  const images = [...document.querySelectorAll('#mermaid')] as HTMLPreElement[];

  if (images.length) {
    const script = document.createElement('script');
    script.src =
      'https://cdn.jsdelivr.net/npm/mermaid@11.2.1/dist/mermaid.min.js';
    script.async = true;
    script.defer = true;
    script.onload = async () => {};
    document.head.appendChild(script);
  }

  images.forEach((image) => {
    image.innerHTML = image.querySelector('code')?.innerHTML || '';
  });
};
