export const handleGalleryImages = () => {
  const images = document.querySelectorAll('.galleryImage');

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.05,
  };

  function handleImg(myImg: IntersectionObserverEntry[]) {
    myImg.forEach((myImgSingle) => {
      if (myImgSingle.intersectionRatio > 0) {
        loadImage(myImgSingle.target as HTMLImageElement);
      }
    });
  }

  function loadImage(image: HTMLImageElement) {
    image.src = image.getAttribute('finalSrc') || '';
  }

  const observer = new IntersectionObserver(handleImg, options);
  images.forEach((img) => {
    observer.observe(img);
    img.addEventListener('click', (event) => {
      const image = event.target as HTMLImageElement;
      image.onerror = () => {
        console.warn('Could not get HD version of image!');
        image.src = image.getAttribute('finalSrc') || '';
      };

      image.src = image.getAttribute('hdSrc') || '';
    });
  });
};
