const socketScript =
  'https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.6.1/socket.io.js';

const socketCallback = () => {
  // @ts-ignore
  if (io) {
    // @ts-ignore
    const socket = io('http://localhost:3000');
    socket.on('reload', function (socket: { path: string }) {
      console.log('Socket', socket);
      if (socket.path.includes('/scripts') || socket.path.includes('.scss')) {
        document.location.reload();
      }

      const formattedPath = socket.path
        .replace('custom/', '/')
        .replace('content/wiki', '')
        .replace('wiki/', '/')
        .replace('content/', '')
        .replace(/\.md(x|)/, '.html')
        .replace('index.html', '');

      console.info(formattedPath);

      if (formattedPath === document.location.pathname) {
        console.log('Reloading...');
        document.location.reload();
      }
    });
  } else {
    console.warn('Socket io loaded but IO global does not exist?');
  }
};

export const localDev = () => {
  if (document.URL.includes('http://localhost')) {
    console.log('Developer mode...');

    const script = document.createElement('script');
    script.src = socketScript;
    script.onload = socketCallback;
    document.head.appendChild(script);
  }
};
