export const importGoat = () => {
  const head = [...(document.getElementsByTagName('head') || [])]?.[0];

  if (head && localStorage.getItem('no-goat') !== 'true') {
    const goatScript = document.createElement('script');
    goatScript.src = '/scripts/external/count.js';
    goatScript.async = true;
    goatScript.setAttribute('data-goatCounter', 'https://goat.pfy.ch/count');

    head.append(goatScript);
  }
};
