import { createSortableTable } from 'pfych-table';

export const wrapTables = () => {
  const tables = [...document.getElementsByTagName('table')];

  tables.forEach((table) => {
    const wrapperDiv = document.createElement('div');
    wrapperDiv.className = 'tableContainer';

    table.parentNode?.insertBefore(wrapperDiv, table);
    wrapperDiv.appendChild(table);
  });
};

export const sortableTables = () => {
  const sortableTables: Element[] = [
    ...(document.querySelectorAll('.sortable table') || []),
  ];

  sortableTables?.map((table) => createSortableTable(table));
};
