export const handlePostImages = () => {
  const galleryImages = document.querySelector('.galleryImage');

  if (galleryImages) {
    return; // Don't run on page with gallery Images
  }

  const images = [...document.getElementsByTagName('img')].filter((image) =>
    image.src.includes('https://assets.pfy.ch/md'),
  );

  images.forEach((image) => {
    image.style.cursor = 'pointer';

    image.addEventListener('click', (event) => {
      const image = event.target as HTMLImageElement;
      const existingImageSource = image.src;

      image.onerror = () => {
        console.warn('Could not get HD version of image!');
        image.src = existingImageSource.replace('/lg/', '/md/');
      };

      image.src = existingImageSource.replace('/md/', '/lg/');
      image.style.cursor = 'default';
    });
  });
};
