import { createElement } from './utils';

const colours = {
  light: '#fff',
  subLight: '#eee',
  dark: '#000',
  subDark: '#111',
};

/** Thank you josh! https://www.joshwcomeau.com/react/dark-mode/ */
export function getInitialColorMode() {
  const persistedColorPreference = window.localStorage.getItem('color-mode');
  const hasPersistedPreference = typeof persistedColorPreference === 'string';

  if (hasPersistedPreference) {
    return persistedColorPreference;
  }

  return 'light';
}

export let colourMode = getInitialColorMode() || 'light';

export const setColourMode = (override?: string) => {
  colourMode = override || getInitialColorMode() || 'light';
  const root = document.documentElement;

  root.style.setProperty(
    '--colour-text',
    colourMode === 'light' ? colours.dark : colours.light,
  );

  root.style.setProperty(
    '--colour-background',
    colourMode === 'light' ? colours.light : colours.dark,
  );

  root.style.setProperty(
    '--colour-background-sub',
    colourMode === 'light' ? colours.subLight : colours.subDark,
  );

  root.style.setProperty(
    '--invert-on-light',
    colourMode === 'light' ? 'invert()' : '',
  );

  root.style.setProperty(
    '--invert-on-dark',
    colourMode === 'light' ? '' : 'invert()',
  );

  root.style.setProperty(
    '--blend-mode',
    colourMode === 'light' ? 'darken' : 'lighten',
  );

  root.style.setProperty(
    '--background-opacity',
    colourMode === 'light' ? '0' : '0.75',
  );

  root.style.setProperty('--tint', colourMode === 'light' ? '0.34' : '0.075');
};

// Add background element to body to handle dark mode overlay
document.addEventListener('DOMContentLoaded', () => {
  document.body.prepend(createElement('div', { className: 'background' }));
});
